import React, { FC } from 'react'

import Layout from '../components/common/Layout'
import SEO from '../components/common/SEO'

const OrderCancelPage: FC = () => (
    <Layout>
        <SEO title='Order - Cancel' />
        <h1>Vorgang abgebrochen</h1>
    </Layout>
)

export default OrderCancelPage
